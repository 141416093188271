<template>
  <div v-loading="loading" style="height: 100vh"></div>
</template>

<script>
import { getWxUser, getQqUser } from '@/utils/serveApi';
import { localStorages } from '@/utils/public';
export default {
  name: 'redirect',
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    const href = location.href;
    const openid = href.split('openid=')[1];
    const qqCallBack = href.split('qqCallBack=')[1];
    if (openid) {
      getWxUser(openid).then(res => {
        this.wxAndQQ(res);
      });
    }
    if (qqCallBack) {
      getQqUser(qqCallBack).then(res => {
        this.wxAndQQ(res);
      });
    }
  },
  methods: {
    wxAndQQ(res) {
      if (res.success && res.data) {
        const { userBackgroundWall, userId, userImg, userName, userPhone, sex, age } = res.data.data;
        const userData = {
          token: res.data.token,
          userBackgroundWall,
          userId,
          userImg,
          userName,
          userPhone,
          sex,
          age
        };
        const ss = { token: 1, userId: 2 };
        localStorages('userData', userData);
        this.$store.commit('userData', userData);
        this.$router.push('/video');
        this.$message('登录成功');
      } else {
        this.$message.error(res.stateDesc);
      }
    }
  }
};
</script>
